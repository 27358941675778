import { round, floor, formatNumber, formatLargeNumber } from '@/utils/math';

export default {
	filters: {
		round,
		floor,
		formatNumber,
		formatLargeNumber,
	},
};
