<template>
	<BaseCardContainer
		class="box is-rounded is-cliackable is-hoverable is-centered"
		@click.native="$emit('onClick')"
	>
		<template #image>
			<div
				v-if="imageUrl"
				class="is-flex is-justify-content-center is-align-items-center box no-box-shadow base-card-image is-rounded"
				:class="backgroundColor"
			>
				<img
					:class="getImagePadding"
					:src="imageUrl"
					:style="[{ width: getImgSize }, { height: getImgSize }]"
				/>
			</div>
			<BaseSkeleton
				v-else
				rounded
				width="100%"
				height="10rem"
				class="base-card-image"
				:class="backgroundColor"
				:animate="false"
			/>
		</template>
		<template #title>{{ title }}</template>
		<template #content>
			<div
				class="is-flex is-flex-direction-column is-justify-content-space-between has-text-centered"
			>
				<div>
					<BaseTag v-if="subtitle" size="normal" color="grey-lighterer" text-color="grey">
						{{ subtitle }}</BaseTag
					>
				</div>
				<span class="mt-2">{{ description }}</span>
			</div>
		</template>
	</BaseCardContainer>
</template>
<script>
import BaseCardContainer from '@/components/base/BaseCardContainer';
import BaseTag from '@/components/base/BaseTag';
import BaseSkeleton from '@/components/base/load/BaseSkeleton';

export default {
	name: 'ShortcutCard',
	components: {
		BaseCardContainer,
		BaseTag,
		BaseSkeleton,
	},
	props: {
		backgroundColor: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		imageUrl: {
			type: String,
			default: '',
		},
		imagePadding: {
			type: String,
			default: '4',
		},
		imageSizePercent: {
			type: String,
			default: '100',
			required: false,
		},
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
	},
	computed: {
		getImagePadding() {
			return 'p-' + this.imagePadding;
		},
		getImgSize() {
			return this.imageSizePercent ? this.imageSizePercent + '%' : '100%';
		},
	},
};
</script>
