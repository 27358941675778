<template>
	<div v-if="errors" class="notification is-danger">
		<p v-for="(propErrors, prop) in errors" :key="prop">
			{{ propErrors[0] }}
		</p>
	</div>
</template>

<script>
export default {
	name: 'ValidationErrors',
	props: {
		errors: {
			type: Object,
			default: null,
		},
	},
};
</script>
