<template>
	<div class="section-list-item py-4">
		<div
			class="columns is-mobile is-variable has-hover-background-blue-lightest has-border-radius is-clickable"
			@click="goToSection"
		>
			<div class="column is-10-mobile is-10-tablet is-8-desktop is-flex is-align-items-center">
				<div class="is-flex-shrink-0 mr-4">
					<ExerciseImage
						v-if="section.icon_url"
						:exercise="section"
						with-difficulty-icon
					/>
				</div>
				<div>
					<p
						v-if="section.prefix"
						class="title is-8 has-text-grey"
						v-text="isExerciseHomework ? 'Øvelse' : section.prefix"
					/>
					<p class="subtitle is-8 has-text-black">
						{{ $designate(section.title, currentDesignation) }}
					</p>
				</div>
			</div>
			<div class="column is-flex is-align-items-center">
				<BaseProgressBar
					v-tippy="{ placement: 'bottom' }"
					:progress="progress"
					:content="`Du har løst ${$options.filters.floor(progress)}% af øvelsen.`"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExerciseImage from '@/components/exercises/ExerciseImage';
import BaseProgressBar from '@/components/base/BaseProgressBar';
import designation from '@/mixins/designation';
import math from '@/mixins/filters/math';
import Section from '@/models/section/Section';

export default {
	name: 'StudentExerciseProgress',
	components: {
		ExerciseImage,
		BaseProgressBar,
	},
	mixins: [designation, math],
	props: {
		section: {
			type: Object,
			required: true,
		},
		homework: {
			type: Object,
			default: null,
		},
		progress: {
			type: Number,
			default: 0,
		},
		courseId: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		...mapGetters('designation', ['getDesignationByCourseId']),
		isExerciseHomework() {
			return this.homework && this.homework.type == 'exercises';
		},
		currentDesignation() {
			if (this.homework?.designation) {
				return this.homework.designation;
			}
			return this.getDesignationByCourseId(this.courseId);
		},
	},
	methods: {
		goToSection() {
			if (this.isExerciseHomework) {
				const parts = new Section(this.section).getPathParts().map(part => {
					return part.id;
				});
				return this.$router.push({
					name: 'exercise_homework',
					params: {
						id: this.homework.id,
					},
					hash: parts.length > 0 ? `#section-${parts[parts.length - 1]}` : '',
				});
			}
			const route = new Section(this.section).getRoute(null);
			if (route) {
				this.$router.push(route);
			}
		},
	},
};
</script>
