export function getAvgOfValues(values = []) {
	const total = values.reduce((total, val) => total + val, 0);
	return total ? total / values.length : 0;
}

export function getPctOf(value = 1, max = 1, capped = true) {
	const pct = value && max ? (value / max) * 100 : 0;
	return capped ? Math.min(pct, 100) : pct;
}

/**
 * Vue filter to round the decimal to the given place.
 * http://jsfiddle.net/bryan_k/3ova17y9/
 * https://gist.github.com/belsrc/672b75d1f89a9a5c192c
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 * @return {Number}
 */
export function round(value, decimals) {
	if (!value) {
		value = 0;
	}

	if (!decimals) {
		decimals = 0;
	}

	value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	return value;
}

export function floor(value, decimals) {
	if (!value) {
		value = 0;
	}

	if (!decimals) {
		decimals = 0;
	}

	value = Math.floor(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
	return value;
}

/**
 * Format larger number with separator of 'thousands'
 *
 * @param  int|float value
 * @param  String separator
 * @return string
 */
export function formatLargeNumber(value, separator = '.') {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

/**
 * Format number with separator for decimals
 *
 * @param  int|float value
 * @param  String separator
 * @param  String target
 * @return string
 */
export function formatNumber(value, separator = ',', target = '.') {
	return value.toString().replace(target, separator);
}
