import multiguard from 'vue-router-multiguard';

import Dashboard from '../views/Dashboard.vue';
import NotFound from '../views/error/NotFound.vue';

import { ifAuthenticated, ifNotAuthenticated, ifTeacher, ifStudent } from './guards';

export default [
	{
		path: '/koekkenvask',
		name: 'kitchensink',
		component: () => import(/* webpackChunkName: "kitchensink" */ '../views/kitchensink/KitchenSink.vue'),
		meta: { layout: 'public', title: 'KitchenSink', hasFluidContainer: true },
	},
	{
		path: '/koekkenvask/indhold',
		name: 'kitchensink-grammar',
		component: () => import(/* webpackChunkName: "kitchensink" */ '../views/kitchensink/GrammarSink.vue'),
		meta: { layout: 'public', title: 'GrammarSink' },
	},
	{
		path: '/',
		redirect: { name: 'dashboard' },
	},

	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dashboard,
		beforeEnter: ifAuthenticated,
		meta: { title: 'Hjem' },
	},

	{
		path: '/soeg',
		name: 'search',
		component: () => import(/* webpackChunkName: "search" */ '../views/search/Search.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Søg' },
	},

	// Bøger
	{
		path: '/boeger',
		name: 'books',
		component: () => import(/* webpackChunkName: "books" */ '../views/books/Books.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Bøger' },
	},
	{
		path: '/bog/:id',
		name: 'book',
		component: () => import(/* webpackChunkName: "books" */ '../views/books/Book.vue'),
		beforeEnter: ifAuthenticated,
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/bog/:book_id/kapitel/:chapter_id',
		name: 'book_chapter',
		component: () => import(/* webpackChunkName: "books" */ '../views/books/BookChapter.vue'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/bog/:book_id/kapitel/:chapter_id/sektion/:section_id',
		name: 'book_chapter_section',
		component: () => import(/* webpackChunkName: "books" */ '../views/books/BookChapterSection.vue'),
		beforeEnter: ifAuthenticated,
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/bog/:id/sitemap',
		name: 'book_sitemap',
		component: () => import(/* webpackChunkName: "books" */ '../views/books/BookSitemap.vue'),
		beforeEnter: ifAuthenticated,
		meta: { hasFullWidthLayout: true },
	},

	// Artikler
	{
		path: '/artikel/:id',
		name: 'article',
		component: () => import(/* webpackChunkName: "articles" */ '../views/articles/Article.vue'),
		beforeEnter: ifAuthenticated,
		meta: { hasFullWidthLayout: true },
	},

	// Opgavesæt
	{
		path: '/opgavesaet',
		name: 'assignments',
		component: () => import(/* webpackChunkName: "assignments" */ '../views/assignments/Assignments.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Opgavesæt' },
	},
	{
		path: '/opgavesaet/:id',
		name: 'assignment',
		component: () => import(/* webpackChunkName: "assignments" */ '../views/assignments/Assignment.vue'),
		beforeEnter: ifAuthenticated,
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/opgavesaet/fag/:course_id/type/:type_id',
		name: 'assignments_by_course_and_type',
		component: () =>
			import(/* webpackChunkName: "assignments" */ '../views/assignments/AssignmentGroup.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Opgavesæt' },
	},

	// Lektier
	{
		path: '/lektier',
		name: 'homework',
		component: () => import(/* webpackChunkName: "homework" */ '../views/homework/Homework.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Lektier' },
	},
	{
		path: '/lektier/:id',
		name: 'exercise_homework',
		component: () => import(/* webpackChunkName: "homework" */ '../views/homework/ExerciseHomework.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifStudent]),
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/lektier/klasse/:class_id',
		name: 'homework_school_class',
		component: () => import(/* webpackChunkName: "homework" */ '../views/homework/HomeworkSchoolClass.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Lektier' },
	},
	{
		path: '/lektier/klasse/:school_class_id/lektie/:homework_id',
		component: () =>
			import(/* webpackChunkName: "homework" */ '../views/homework/teacher/HomeworkLayout.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		children: [
			{
				path: '/',
				name: 'homework_details',
				component: () =>
					import(
						/* webpackChunkName: "homework" */ '../views/homework/teacher/HomeworkDetails.vue'
					),
			},
			{
				path: 'elev/:user_id',
				name: 'homework_student_report',
				component: () =>
					import(
						/* webpackChunkName: "homework" */ '../views/homework/teacher/StudentDetails.vue'
					),
			},
			{
				path: 'oevelse/:section_relation_id',
				name: 'homework_exercise_report',
				component: () =>
					import(
						/* webpackChunkName: "homework" */ '../views/homework/teacher/ExerciseDetails.vue'
					),
			},
		],
	},
	{
		path: '/lektier/klasse/:class_id/opret',
		name: 'homework_create',
		component: () =>
			import(/* webpackChunkName: "homework" */ '../views/homework/HomeworkCreateMaterialSelect.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Opret lektie' },
	},
	{
		path: '/lektier/klasse/:class_id/opret/bog/:book_id',
		name: 'homework_create_book',
		component: () => import(/* webpackChunkName: "homework" */ '../views/homework/HomeworkCreateBook.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Opret lektie', hasFullWidthLayout: true },
	},
	{
		path: '/lektier/klasse/:class_id/opret/opgavesaet/:course_id',
		name: 'homework_create_assignment',
		component: () =>
			import(/* webpackChunkName: "homework" */ '../views/homework/HomeworkCreateAssignment.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Opret lektie', hasFullWidthLayout: true },
	},
	{
		path: '/lektier/klasse/:class_id/opret/oevelser/:course_id',
		name: 'homework_create_exercises',
		component: () =>
			import(/* webpackChunkName: "homework" */ '../views/homework/HomeworkCreateExercises.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Opret lektie', hasFullWidthLayout: true },
	},
	{
		path: '/lektier/klasse/:class_id/rediger/:homework_id',
		name: 'homework_edit',
		component: () => import(/* webpackChunkName: "homework" */ '../views/homework/HomeworkEdit.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Rediger lektie', hasFullWidthLayout: true },
	},
	{
		path: '/lektier/klasse/:class_id/opret/traening/:course_id',
		name: 'homework_create_training',
		component: () =>
			import(/* webpackChunkName: "homework" */ '../views/homework/HomeworkCreateTraining.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Opret adaptiv træning', hasFullWidthLayout: true },
	},
	{
		path: '/traening/:id',
		name: 'training_homework',
		component: () => import(/* webpackChunkName: "homework" */ '../views/homework/TrainingHomework.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifStudent]),
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/lektier/klasse/:school_class_id/traening/:homework_id',
		name: 'training_homework_details',
		component: () =>
			import(/* webpackChunkName: "homework" */ '../views/homework/TrainingHomeworkDetails.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
	},

	// Teacher admin
	{
		path: '/laerer/administration',
		redirect: { name: 'school_classes' },
	},
	{
		path: '/laerer/administration/hold/opret',
		redirect: { name: 'create_school_class' },
	},
	{
		path: '/laerer/administration/hold/:school_class_id',
		redirect: to => {
			return {
				name: 'school_class_details',
				params: { school_class_id: to.params.school_class_id },
			};
		},
	},
	{
		path: '/hold',
		name: 'school_classes',
		component: () => import(/* webpackChunkName: "teacher" */ '../views/teacher/Administration.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Hold' },
	},
	{
		path: '/hold/alle',
		name: 'school_classes_all',
		component: () =>
			import(/* webpackChunkName: "teacher" */ '../views/teacher/AdministrationExtended.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Alle skolens hold' },
	},
	{
		path: '/hold/skolens-elever',
		name: 'school_students',
		component: () => import(/* webpackChunkName: "teacher" */ '../views/teacher/SchoolStudents.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Alle skolens elever' },
	},

	{
		path: '/hold/opret',
		name: 'create_school_class',
		component: () => import(/* webpackChunkName: "teacher" */ '../views/teacher/SchoolClassCreate.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Opret hold', hasFullWidthLayout: true },
	},
	{
		path: '/hold/:school_class_id',
		component: () =>
			import(/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/SchoolClassDetails.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		children: [
			{
				path: '/',
				name: 'school_class_details',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/Students.vue'
					),
			},
			{
				path: 'elevresultater/:course_id?',
				name: 'school_class_studentsresults',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/StudentsResults.vue'
					),
			},
			{
				path: 'unilogin',
				name: 'school_class_unilogin',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/Unilogin.vue'
					),
			},
			{
				path: 'log',
				name: 'school_class_log',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/Log.vue'
					),
			},
			{
				path: 'tilfoej-elever',
				name: 'school_class_addstudents',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/AddStudents.vue'
					),
			},
			{
				path: 'holdkode',
				name: 'school_class_joincode',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/JoinCode.vue'
					),
			},
			{
				path: 'unilogin-elever',
				name: 'school_class_unilogin_students',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/UniloginStudents.vue'
					),
			},
			{
				path: 'indstillinger',
				name: 'school_class_settings',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/Settings.vue'
					),
			},
			{
				path: 'lektier/:course_id?',
				name: 'school_class_homework',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/Homework.vue'
					),
			},
			{
				path: 'screeninger/:course_id?',
				name: 'school_class_screening_homework',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/ScreeningHomework.vue'
					),
			},
			{
				path: 'boeger/:course_id?',
				name: 'school_class_books',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/Books.vue'
					),
			},
			{
				path: 'opgavesaet/:course_id?',
				name: 'school_class_assignments',
				component: () =>
					import(
						/* webpackChunkName: "schoolclasses" */ '../views/schoolclasses/Assignments.vue'
					),
			},
		],
	},

	// Resultater
	{
		path: '/resultater',
		name: 'results',
		component: () => import(/* webpackChunkName: "results" */ '../views/results/SchoolClasses.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Resultater' },
	},
	{
		path: '/resultater/hold/:school_class_id',
		name: 'results_school_class_materials',
		component: () => import(/* webpackChunkName: "results" */ '../views/results/SchoolClassMaterials.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
	},
	{
		path: '/resultater/hold/:school_class_id/bog/:book_id',
		name: 'results_school_class_book_chapters',
		component: () => {
			return import(/* webpackChunkName: "results" */ '../views/results/SchoolClassBookChapters.vue');
		},
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/resultater/hold/:school_class_id/bog/:book_id/kapitel/:chapter_relation_id',
		name: 'results_school_class_book_chapter_stats',
		component: () => {
			return import(
				/* webpackChunkName: "results" */ '../views/results/SchoolClassBookChapterStats.vue'
			);
		},
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/resultater/hold/:school_class_id/opgavesaet/fag/:course_id/:type_id?',
		name: 'results_school_class_course_assignments',
		component: () => {
			return import(
				/* webpackChunkName: "results" */ '../views/results/SchoolClassCourseAssignments.vue'
			);
		},
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/resultater/hold/:school_class_id/opgavesaet/:assignment_id',
		name: 'results_school_class_assignment_stats',
		component: () => {
			return import(
				/* webpackChunkName: "results" */ '../views/results/SchoolClassAssignmentStats.vue'
			);
		},
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { hasFullWidthLayout: true },
	},

	// Task solution
	{
		path: '/oevelse/:exercise_id/facit',
		name: 'exercise_solutions',
		component: () => import(/* webpackChunkName: "exercises" */ '../views/exercises/Solutions.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Facit', hasFullWidthLayout: true },
	},
	{
		path: '/oevelse/:exercise_id/elev/:user_id',
		name: 'student_exercise',
		component: () =>
			import(/* webpackChunkName: "exercises" */ '../views/exercises/ExerciseWithAnswers.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Besvarelse', hasFullWidthLayout: true },
	},

	// Screeninger
	{
		path: '/screeninger',
		name: 'screenings',
		component: () => import(/* webpackChunkName: "screenings" */ '../views/screenings/Screenings.vue'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/screeninger/mine',
		name: 'my_screenings',
		component: () => import(/* webpackChunkName: "screenings" */ '../views/screenings/Screenings.vue'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/screeninger/kolleger',
		name: 'colleague_screenings',
		component: () => import(/* webpackChunkName: "screenings" */ '../views/screenings/Screenings.vue'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/screeninger/opret/type/:id',
		name: 'screening_create_select_from_of_type',
		component: () =>
			import(
				/* webpackChunkName: "screenings" */ '../views/screenings/ScreeningCreateSelectFromOfType.vue'
			),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
	},
	{
		path: '/screeninger/opret/:id',
		name: 'screening_create',
		component: () => import(/* webpackChunkName: "screenings" */ '../views/screenings/ScreeningCreate.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/screeninger/klasse/:class_id',
		name: 'screenings_school_class',
		component: () =>
			import(/* webpackChunkName: "screenings" */ '../views/screenings/ScreeningsSchoolClass.vue'),
		beforeEnter: ifAuthenticated,
	},
	{
		path: '/screeninger/klasse/:school_class_id/screening/:homework_id',
		component: () =>
			import(/* webpackChunkName: "screenings" */ '../views/homework/teacher/HomeworkLayout.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		children: [
			{
				path: '/',
				name: 'screening_homework_details',
				component: () =>
					import(
						/* webpackChunkName: "screenings" */ '../views/homework/teacher/HomeworkDetails.vue'
					),
			},
			{
				path: 'elev/:user_id',
				name: 'screening_homework_student_report',
				component: () =>
					import(
						/* webpackChunkName: "screenings" */ '../views/homework/teacher/StudentDetails.vue'
					),
			},
			{
				path: 'oevelse/:section_relation_id',
				name: 'screening_homework_exercise_report',
				component: () =>
					import(
						/* webpackChunkName: "screenings" */ '../views/homework/teacher/ExerciseDetails.vue'
					),
			},
		],
	},
	{
		path: '/screeninger/klasse/:class_id/rediger/:homework_id',
		name: 'screening_edit',
		component: () => import(/* webpackChunkName: "screenings" */ '../views/screenings/ScreeningEdit.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { hasFullWidthLayout: true },
	},
	{
		path: '/screeninger/:id',
		name: 'screening',
		component: () => import(/* webpackChunkName: "screenings" */ '../views/screenings/Screening.vue'),
		beforeEnter: ifAuthenticated,
		meta: { hasFullWidthLayout: true },
	},

	// Settings
	{
		path: '/profil',
		alias: '/profil/afmeld', // Used by email "unsubscribe" links for tracking purposes.
		name: 'profile',
		component: () => import(/* webpackChunkName: "settings" */ '../views/settings/Profile.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Profil' },
	},
	{
		path: '/skift-skole',
		name: 'switch-school',
		component: () => import(/* webpackChunkName: "settings" */ '../views/settings/SwitchSchool.vue'),
		beforeEnter: ifAuthenticated,
		meta: { title: 'Skift skole' },
	},

	// Student report
	{
		path: '/elev/:user_id',
		name: 'student_report',
		component: () => import(/* webpackChunkName: "student_report" */ '../views/student/StudentReport.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Elevrapport' },
	},

	// Auth routes
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login.vue'),
		beforeEnter: ifNotAuthenticated,
		meta: { layout: 'auth', title: 'Log ind' },
	},
	{
		path: '/login/token',
		name: 'login_with_token',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/LoginFromToken.vue'),
		beforeEnter: ifNotAuthenticated,
		meta: { layout: 'public', title: 'Log ind' },
	},
	{
		path: '/auth/autologin',
		name: 'login_with_signed_url',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/LoginFromSignedUrl.vue'),
		beforeEnter: ifNotAuthenticated,
		meta: { layout: 'public', title: 'Log ind' },
	},
	{
		path: '/vaelgskole',
		name: 'select-school',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/SelectSchool.vue'),
		beforeEnter: ifAuthenticated,
		meta: { layout: 'public', title: 'Vælg skole' },
	},
	{
		path: '/adgangskode/nulstil',
		name: 'forgot-password',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ForgotPassword.vue'),
		beforeEnter: ifNotAuthenticated,
		meta: { layout: 'auth', title: 'Glemt kodeord' },
	},
	{
		path: '/adgangskode/nulstil/:token',
		name: 'reset-password',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword.vue'),
		beforeEnter: ifNotAuthenticated,
		meta: { layout: 'auth', title: 'Nultil adgangskode' },
	},
	{
		path: '/login/opret',
		name: 'team-signup',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/TeamSignup.vue'),
		meta: { layout: 'auth', title: 'Tilmeld hold' },
	},
	{
		path: '/auth/unilogin',
		name: 'auth-unilogin',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/UniLogin.vue'),
		beforeEnter: ifNotAuthenticated,
		meta: { layout: 'public' },
	},
	{
		path: '/unilogin/redirect',
		name: 'unilogin-redirect',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/UniLoginRedirect.vue'),
		beforeEnter: ifNotAuthenticated,
		meta: { layout: 'public' },
	},
	{
		path: '/unilogin/login',
		name: 'unilogin-login',
		component: () => import(/* webpackChunkName: "auth" */ '../views/auth/UniLoginCallback.vue'),
		meta: { layout: 'public' },
	},
	{
		path: '/unilogin/trial',
		name: 'trial-unilogin-callback',
		component: () => import(/* webpackChunkName: "auth" */ '../views/trial/UniLogin.vue'),
		meta: { layout: 'public' },
	},

	// Trial routes
	{
		path: '/trial',
		name: 'trial',
		component: () => import(/* webpackChunkName: "trial" */ '../views/trial/Trial.vue'),
		meta: { layout: 'public', title: 'Opret gratis prøveperiode', hasFullWidthLayout: true },
	},
	{
		path: '/trial/unilogin', // Deprecated
		name: 'trial-unilogin',
		component: () => import(/* webpackChunkName: "trial" */ '../views/trial/UniLogin.vue'),
		meta: { layout: 'public' },
	},

	// Developer routes
	{
		path: '/development',
		name: 'development',
		component: () => import(/* webpackChunkName: "trial" */ '../views/Development.vue'),
		beforeEnter: multiguard([ifAuthenticated, ifTeacher]),
		meta: { title: 'Development' },
	},

	// 404 catch all
	{
		path: '*',
		name: 'not-found',
		component: NotFound,
		meta: { title: 'Siden findes ikke' },
	},
];
