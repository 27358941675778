import http from './http';

function schoolClassRoute(schoolId, schoolClassId, path = '') {
	return `/schools/${schoolId}/classes/${schoolClassId}/results/${path}`;
}

function studentRoute(schoolId, userId, path = '') {
	return `/schools/${schoolId}/students/${userId}/results/${path}`;
}

export function getSchoolClassResultsOfBooks(schoolId, schoolClassId, bookIds) {
	const params = { book_ids: bookIds };
	return http.get(schoolClassRoute(schoolId, schoolClassId, 'books'), { params }).then(({ data }) => data.data);
}

export function getSchoolClassResultsOfBookChapters(schoolId, schoolClassId, bookId) {
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, `books/${bookId}/chapters`))
		.then(({ data }) => data.data);
}

export function getSchoolClassResultsOfAssignments(schoolId, schoolClassId, assignmentIds) {
	const params = { assignment_ids: assignmentIds };
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, 'assignments'), { params })
		.then(({ data }) => data.data);
}

export function getSchoolClassResultsOfAssignmentCourseTypes(school_id, school_class_id, course_ids) {
	const params = { course_ids };
	return http
		.get(schoolClassRoute(school_id, school_class_id, 'assignments/typesbycourse'), { params })
		.then(({ data }) => data.data);
}

export function getSchoolClassResultsOfExercises(schoolId, schoolClassId, sectionRelationIds) {
	const params = { ids: sectionRelationIds };
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, 'exercises'), { params })
		.then(({ data }) => data.data);
}

export function getSchoolClassExerciseAnswerRevisions(schoolId, schoolClassId, sectionRelationId, homeworkId = null) {
	const params = homeworkId ? { homework_id: homeworkId } : {};
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, `exercises/${sectionRelationId}/answerrevisions`), {
			params,
		})
		.then(({ data }) => data.data);
}

export function getSchoolClassHomeworkAnswerRevisionSummaries(schoolId, schoolClassId, homeworkId) {
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, `homework/${homeworkId}/answerrevisionsummaries`))
		.then(({ data }) => data.data);
}

export function getSchoolClassHomeworkSectionAnswerRevisions(schoolId, schoolClassId, homeworkId, sectionRelationId) {
	return http
		.get(
			schoolClassRoute(
				schoolId,
				schoolClassId,
				`homework/${homeworkId}/sectionrelation/${sectionRelationId}/answerrevisions`,
			),
		)
		.then(({ data }) => data.data);
}

export function getSchoolClassHomeworkUserAnswerRevisions(schoolId, schoolClassId, homeworkId, userId) {
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, `homework/${homeworkId}/user/${userId}/answerrevisions`))
		.then(({ data }) => data.data);
}

export function requestExport(schoolId, schoolClassId, homeworkId, type = 'results') {
	return http.post(schoolClassRoute(schoolId, schoolClassId, `homework/${homeworkId}/request-export`), { type });
}

export function getSchoolClassStudentTotals(schoolId, schoolClassId) {
	return http.get(schoolClassRoute(schoolId, schoolClassId, 'studenttotals')).then(({ data }) => data.data);
}

export function getSchoolClassStudentCourseTotals(schoolId, schoolClassId, courseId) {
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, `studentscoursetotals/${courseId}`))
		.then(({ data }) => data.data);
}

export function getSchoolClassScreeningFocusareas(schoolId, schoolClassId) {
	return http.get(schoolClassRoute(schoolId, schoolClassId, 'screeningfocus')).then(({ data }) => data.data);
}

export function getSchoolClassHomeworkStudentProgresses(schoolId, schoolClassId) {
	return http
		.get(schoolClassRoute(schoolId, schoolClassId, 'homework/studentprogresses'))
		.then(({ data }) => data.data);
}

export function getStudentOverview(school_id, user_id, course_id, school_class_id) {
	return http
		.get(studentRoute(school_id, user_id, 'overview'), { params: { course_id, school_class_id } })
		.then(({ data }) => data.data);
}

export function getStudentLatestExercises(school_id, user_id, course_id, school_class_id, only_screenings, page = 1) {
	return http
		.get(studentRoute(school_id, user_id, 'latestexercises'), {
			params: { course_id, school_class_id, only_screenings, page },
		})
		.then(({ data }) => data);
}

export function getStudentLatestScreenings(school_id, user_id) {
	return http.get(studentRoute(school_id, user_id, 'latestscreenings')).then(({ data }) => data);
}

export function getStudentExerciseAnswerRevisions(school_id, user_id, section_relation_id, homework_id = null) {
	return http
		.get(studentRoute(school_id, user_id, `exercises/${section_relation_id}/answerrevisions`), {
			params: { homework_id },
		})
		.then(({ data }) => data.data);
}

export function getUserLatestUnfinishedExercises(school_id) {
	return http.get(`/schools/${school_id}/user/results/latestunfinished`).then(({ data }) => data.data);
}
