<template>
	<div>
		<Header v-if="isAuthenticated" />

		<section :class="{ section: hasContainer, 'pt-5': hasContainer }">
			<div :class="containerClasses">
				<slot v-if="isAuthenticated" />
			</div>

			<Footer />
		</section>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import Header from './Header';
import Footer from './Footer';

export default {
	name: 'Layout',
	components: {
		Header,
		Footer,
	},
	computed: {
		...mapGetters('auth', ['isAuthenticated']),
		hasContainer() {
			return this.$route.meta.hasFullWidthLayout !== true;
		},
		hasFluidContainer() {
			return this.hasContainer && this.$route.meta.hasFluidContainer === true;
		},
		containerClasses() {
			return {
				container: this.hasContainer,
				'is-widescreen': this.hasContainer,
				'is-fluid': this.hasFluidContainer,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.section {
	padding-bottom: 0;
}
</style>
