<template>
	<div>
		<SplitModal
			ref="storeAdaptiveExercise"
			title="Adaptiv træning"
			:reduce-top-margin="true"
			:image-width="5"
			:left-column-classes="[
				'has-background-yellow-soft',
				'is-flex',
				'is-justify-content-center',
				'is-align-items-center',
			]"
			class="create-homework-modal"
			@hide="$emit('hide')"
		>
			<template #img>
				<img :src="imageUrl" class="modal-image" alt="adaptive-training" />
			</template>
			<template #body>
				<ValidationErrors :errors="validationErrors" />
				<div class="is-flex">
					<BaseField has-icon-right class="dropdown-container">
						<BaseSelect
							v-model="selectedSchoolClass"
							:options="schoolClasses"
							placeholder="Vælg hold"
							id-key="id"
							:display-option="option => (option ? option.name : '')"
							@input="schoolClassSelected"
						/>
					</BaseField>

					<BaseField has-icon-right class="dropdown-container">
						<BaseSelect
							v-model="selectedCourse"
							:options="schoolClassCourses"
							:disabled="selectedSchoolClass === null"
							placeholder="Vælg fag"
							id-key="id"
							:display-option="option => (option ? option.title : '')"
						/>
					</BaseField>
				</div>
				<div class="pt-1">
					<BaseField label="Eleverne skal arbejde i:" has-icon-right>
						<BaseSelect
							v-model="duration"
							:options="durations"
							:display-option="option => `${option} minutter`"
						/>
					</BaseField>
				</div>
				<div class="mt-4 has-background-blue-lighterer box no-box-shadow is-size-8">
					Bemærk, at dette er en genvej til at oprette en hurtig adaptiv træning. Hvis du
					gerne vil have mere kontrol over den adaptive træning, kan du
					<router-link :to="{ name: 'homework' }"
						>oprette en rigtig adaptiv træning her.
					</router-link>
				</div>
			</template>
			<template #footer>
				<div class="buttons is-right">
					<button
						:disabled="
							selectedSchoolClass === null ||
							selectedCourse === null ||
							duration === null ||
							duration === 0 ||
							isLoading
						"
						type="button"
						class="button is-medium is-primary"
						@click="createAdaptiveExercises"
					>
						Opret
					</button>
				</div>
			</template>
		</SplitModal>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseField from '@/components/base/input/BaseField';
import BaseSelect from '@/components/base/input/BaseSelect';
import dayjs from 'dayjs';
import designation from '@/mixins/designation';
import Designation from '@/models/designation/Designation';
import SplitModal from '@/components/ui/modal/SplitModal';
import { ValidationError } from '@/api/errors';
import ValidationErrors from '@/components/ui/ValidationErrors';

export default {
	name: 'AdaptiveTrainingModal',

	components: {
		BaseField,
		BaseSelect,
		SplitModal,
		ValidationErrors,
	},
	mixins: [designation],
	props: {
		maxDuration: {
			type: Number,
			default: 60,
		},
	},
	data() {
		return {
			isLoading: false,
			categories: [],
			defaultLevel: 10,
			designationOptions: Designation.getDesignationTypes(),
			duration: 20,
			payload: [],
			schoolClassesAll: [],
			selectedCategories: [],
			selectedCourse: null,
			selectedSchoolClass: null,
			symbolOptions: Designation.getSymbolTypes(),
			validationErrors: null,
		};
	},
	computed: {
		...mapState('user', ['user', 'schoolId']),
		...mapGetters('school', ['schoolClasses']),
		...mapGetters('designation', ['getDesignationByCourseId']),
		courseDesignation() {
			return this.getDesignationByCourseId(this.selectedCourse.id, this.selectedSchoolClass.id);
		},
		imageUrl() {
			return '/images/dashboard/pulldown_machine.svg';
		},
		schoolClassCourses() {
			if (this.selectedSchoolClass) {
				return this.selectedSchoolClass.courses;
			}
			return [];
		},
		durations() {
			const durations = [];
			let duration = 0;
			while (duration < this.maxDuration) {
				duration += 5;
				durations.push(duration);
			}
			return durations;
		},
	},
	mounted() {
		this.$refs.storeAdaptiveExercise.show();
	},
	methods: {
		...mapActions('homeworkFlow', ['updateSettings', 'updateGlobalExcludedStudents']),
		schoolClassSelected(schoolClass) {
			if (schoolClass.courses.length === 0) {
				this.selectedCourse = null;
			}
			if (schoolClass.courses.length === 1) {
				this.selectedCourse = schoolClass.courses[0];
			}
		},
		createAdaptiveExercises() {
			this.isLoading = true;
			this.$store.dispatch('homeworkFlow/resetAll');
			Promise.all([
				this.loadCourseCategories(),
				this.loadSchoolClassStartExerciseLevel(),
				this.$refreshCourseDesignation(this.selectedCourse.id, this.selectedSchoolClass.id),
			])
				.then(() => {
					this.selectAllCategories();
				})
				.finally(() => {
					this.payload = this.buildPayload();
					this.storeAdaptiveExercise();
				});
		},
		loadCourseCategories() {
			return this.$store
				.dispatch('training/getCourseCategories', {
					courseId: this.selectedCourse.id,
				})
				.then(categories => (this.categories = categories));
		},
		loadSchoolClassStartExerciseLevel() {
			return this.$store
				.dispatch('training/getSchoolClassStartLevel', this.selectedSchoolClass.id)
				.then(level => {
					this.defaultLevel = level;
				});
		},
		storeAdaptiveExercise() {
			this.validationErrors = null;
			this.$store
				.dispatch('homework/createHomework', {
					school_class_id: this.selectedSchoolClass.id,
					data: this.payload,
				})
				.then(homework => {
					if (!homework) {
						return;
					}
					this.$store.dispatch('homeworkFlow/resetAll');
					this.$toasted.show('Adaptiv lektie er nu oprettet', {
						position: 'top-center',
						type: 'success',
						duration: 5000,
					});
					this.$router.push({
						name: 'training_homework_details',
						params: {
							school_class_id: this.selectedSchoolClass.id,
							homework_id: homework.id,
						},
						query: {
							created: true,
						},
					});
				})
				.catch(err => {
					if (err instanceof ValidationError) {
						this.validationErrors = err.validationErrors;
					}
				});
		},
		buildPayload() {
			const timeStart = dayjs().millisecond(0).second(0);
			const timeEnd = dayjs().add(1, 'day').endOf('day').subtract(1, 'second');

			const payload = {
				taxonomy_category_ids: this.selectedCategories.map(category => category.id),
				course_id: this.selectedCourse.id,
				teacher_id: this.user.id,
				school_class_id: this.selectedSchoolClass.id,
				released_at: timeStart.format('YYYY-MM-DD HH:mm:ss'),
				deadline_at: timeEnd.format('YYYY-MM-DD HH:mm:ss'),
				has_visible_results: true,
				has_visible_points: true,
				has_visible_instructions: true,
				excluded_students: null,
				conf: {
					title: 'Træning',
					message: 'Blandede træningsøvelser tilpasset dig',
					duration: this.duration,
					start_level: this.defaultLevel,
					is_pausable: true,
				},
				designation: {
					default: this.courseDesignation.default,
					symbol: this.courseDesignation.symbol,
				},
			};

			return payload;
		},
		selectAllCategories(categories = this.categories) {
			categories.forEach(category => {
				if (!this.categoryIsSelected(category)) {
					this.selectedCategories.push(category);
				}
				if (category.categories && category.categories.length > 0) {
					this.selectAllCategories(category.categories);
				}
			});
		},
		categoryIsSelected(category) {
			return this.selectedCategories.includes(category);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.modal-image {
	max-width: 80%;
	width: 100% !important;
	height: auto !important;
}
</style>
