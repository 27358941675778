<template>
	<div class="teacher-insights-graph">
		<div class="is-flex is-justify-content-space-between">
			<div class="is-flex is-align-items-center">
				<ExerciseIcon type="points" color="gold" size="large" no-padding />
				<span class="title is-6 pl-2">{{ selectedType.title }}</span>
			</div>
			<div v-if="schoolClasses.length > 0" class="insight-options">
				<div class="insight-options-left-border"></div>
				<div class="insight-options-bottom-border"></div>
				<div class="teacher-insights-dropdown">
					<BaseDropdown is-right no-height-cap>
						<template #trigger="slotProps">
							<div class="is-flex is-align-items-center has-text-blue-light">
								<div class="pr-3">
									{{ selectedSchoolClasses.length }} hold
								</div>
								<div class="pl-2">
									<BaseChevron
										color="blue-light"
										:direction="
											slotProps.active ? 'up' : 'down'
										"
									/>
								</div>
							</div>
						</template>
						<div class="px-4" style="width: 200px">
							<BaseField has-icon-right class="dropdown-container">
								<BaseMultiSelect
									v-model="selectedSchoolClasses"
									:options="schoolClasses"
									no-deselect
									no-select-all
									placeholder="Hold"
									id-key="id"
									:display-option="
										option => (option ? option.name : '')
									"
								/>
							</BaseField>
							<BaseField has-icon-right class="dropdown-container">
								<BaseSelect
									v-model="selectedAnswers"
									:options="answerOptions"
									no-deselect
									no-height-cap
									close-on-click
									placeholder="Besvarelser"
									id-key="id"
									:display-option="
										option => (option ? option.title : '')
									"
								/>
							</BaseField>
							<BaseField has-icon-right class="dropdown-container">
								<BaseSelect
									v-model="selectedType"
									:options="typeOptions"
									no-deselect
									no-height-cap
									close-on-click
									placeholder="Målepunkter"
									id-key="id"
									:display-option="
										option => (option ? option.title : '')
									"
								/>
							</BaseField>
							<BaseField has-icon-right class="dropdown-container">
								<BaseSelect
									v-model="selectedPeriod"
									:options="periodOptions"
									no-deselect
									no-height-cap
									close-on-click
									placeholder="Målepunkter"
									id-key="id"
									:display-option="
										option => (option ? option.title : '')
									"
								/>
							</BaseField>
						</div>
					</BaseDropdown>
				</div>
			</div>
		</div>
		<div class="pt-4" style="aspect-ratio: 10 / 4">
			<div v-if="hasLoaded">
				<LineChart :datasets="datasets" :labels="labels" :padding="0" />
			</div>
		</div>
	</div>
</template>

<script>
import BaseChevron from '@/components/base/BaseChevron';
import BaseDropdown from '@/components/base/dropdown/BaseDropdown';
import BaseField from '@/components/base/input/BaseField';
import BaseMultiSelect from '@/components/base/input/BaseMultiSelect';
import BaseSelect from '@/components/base/input/BaseSelect';
import ExerciseIcon from '@/components/exercises/ExerciseIcon';
import LineChart from '@/components/ui/charts/LineChart';
import ChartOptions from '@/components/ui/charts/ChartOptions';
import { getCachedItem, cacheItem } from '@/store/cachePersistent';
import { mapGetters } from 'vuex';

export default {
	name: 'TeacherInsightsDropdown',
	components: {
		BaseChevron,
		BaseDropdown,
		BaseField,
		BaseMultiSelect,
		BaseSelect,
		ExerciseIcon,
		LineChart,
	},
	props: {},
	data() {
		return {
			selectedSchoolClasses: [],
			selectedAnswers: null,
			answerOptions: [
				{ id: 'homework', title: 'Holdets lektier' },
				{ id: 'all', title: 'Alle besvarelser' },
			],
			selectedType: null,
			typeOptions: [
				{ id: 'points_per_student', title: 'Point / elev' },
				{ id: 'average_per_answer_revision', title: 'Gns. / besvarelse' },
			],
			selectedPeriod: null,
			periodOptions: [
				{ id: '12weeks', title: '12 uger' },
				{ id: '12months', title: '12 måneder' },
			],
			labels: [],
			datasets: [],
			hasLoaded: false,
		};
	},
	computed: {
		...mapGetters('school', ['schoolClasses']),
		...mapGetters('user', ['user']),
	},
	watch: {
		selectedSchoolClasses() {
			this.fetchData();
		},
		selectedAnswers() {
			this.fetchData();
		},
		selectedType() {
			this.fetchData();
		},
		selectedPeriod() {
			this.fetchData();
		},
	},
	created() {
		this.getCachedConfig();

		setTimeout(() => {
			this.hasLoaded = true;
			this.fetchData();
		}, 1000);
	},
	methods: {
		cacheConfig() {
			if (this.selectedSchoolClasses.length > 0) {
				cacheItem('teacher_insights_graph_' + this.user.id, {
					school_classes: this.selectedSchoolClasses.map(e => e.id),
					answer: this.selectedAnswers.id,
					type: this.selectedType.id,
					period: this.selectedPeriod.id,
				});
			}
		},
		getCachedConfig() {
			const config = getCachedItem('teacher_insights_graph_' + this.user.id);
			if (config) {
				this.selectedSchoolClasses = this.schoolClasses.filter(e =>
					config.school_classes.includes(e.id),
				);
				this.selectedAnswers = this.answerOptions.find(e => e.id === config.answer);
				this.selectedType = this.typeOptions.find(e => e.id === config.type);
				this.selectedPeriod = this.periodOptions.find(e => e.id === config.period);
			} else {
				this.selectedSchoolClasses = this.schoolClasses.slice(0, 2);
				this.selectedAnswers = this.answerOptions[0];
				this.selectedType = this.typeOptions[0];
				this.selectedPeriod = this.periodOptions[0];
			}
		},
		fetchData() {
			if (!this.hasLoaded || this.selectedSchoolClasses.length === 0) {
				return;
			}
			return this.$store
				.dispatch('user/getInsightGraphData', {
					school_classes: this.selectedSchoolClasses.map(e => e.id),
					answers: this.selectedAnswers.id,
					type: this.selectedType.id,
					period: this.selectedPeriod.id,
				})
				.then(data => {
					this.labels = data.labels;
					this.datasets = this.getDatasets(data.data);
					this.cacheConfig();
				});
		},
		getDatasets(data) {
			const res = [];
			const colorNames = ['blue', 'red', 'green', 'yellow', 'grey'];
			const schoolClasses = this.schoolClasses.filter(e =>
				this.selectedSchoolClasses.map(e => e.id).includes(e.id),
			);
			for (let i = 0; i < schoolClasses.length; i++) {
				const schoolClass = schoolClasses[i];
				const color = ChartOptions.rgbColor(colorNames[i % colorNames.length]);
				res.push({
					label: schoolClass.name,
					data: data[schoolClass.id] ?? [],
					borderColor: color,
					backgroundColor: color,
				});
			}
			return res;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.teacher-insights-graph {
	white-space: nowrap;
	.insight-options {
		background-color: $white;
		position: absolute;
		top: 0;
		right: 0;
		width: 10rem;
		height: calc(2 * $radius-larger);
		border-bottom-left-radius: $radius-larger;
		padding: calc($radius-larger / 2) $radius-larger;
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.insight-options-left-border,
		.insight-options-bottom-border {
			width: $radius-larger;
			height: $radius-larger;
			position: absolute;
			background-color: $white;

			&:after {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				background-color: $blue-lightest;
				border-top-right-radius: $radius-larger;
			}
		}

		.insight-options-left-border {
			top: 0;
			left: -$radius-larger;
		}

		.insight-options-bottom-border {
			right: 0;
			bottom: -$radius-larger;
		}
	}
}
</style>

<style lang="scss">
.teacher-insights-dropdown {
	.dropdown:not(.is-active) > .dropdown-menu {
		display: none;
	}
}
</style>
