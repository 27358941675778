import {
	getSchoolClass,
	getSchoolTeachers,
	getSchoolClassLevels,
	getSchoolClasses,
	getSchoolClassesAll,
	getSchoolStudents,
	getClassByCode,
	joinClassByCode,
	joinSchool,
	getSchoolClassCreateFlow,
	getSchoolClassCourses,
	getSchoolClassCourseMaterials,
	createSchoolClass,
	updateSchoolClass,
	getSchoolClassActions,
	removeStudentFromSchoolClass,
	deleteSchoolClass,
	restoreSchoolClass,
	addTeacherToSchoolClass,
	removeTeacherFromSchoolClass,
	getSchoolClassUniloginSync,
	resetStudentLogin,
	renameStudent,
	addBooksToSchoolClass,
	removeBookFromSchoolClass,
} from '@/api/school';

const state = () => ({
	schoolClasses: [],
});

const getters = {
	schoolClasses: state => state.schoolClasses,
};

const actions = {
	getSchoolClass(_, { schoolId, schoolClassId }) {
		return getSchoolClass(schoolId, schoolClassId);
	},
	getSchoolTeachers(_, schoolId) {
		return getSchoolTeachers(schoolId);
	},
	getSchoolClassLevels(_, schoolId) {
		return getSchoolClassLevels(schoolId);
	},
	getSchoolClasses({ commit, rootState, rootGetters }, archived = false) {
		// If student impersonation is active:
		// Bypass impersonation by using base auth token for request
		const bypassImpersonation = rootGetters['studentImpersonation/isActive'];
		return getSchoolClasses(rootState.user.schoolId, archived, bypassImpersonation).then(schoolClasses => {
			if (!archived) {
				commit('setSchoolClasses', schoolClasses);
			}
			return schoolClasses;
		});
	},
	getSchoolClassesAll({ rootState, rootGetters }, archived = false) {
		// If student impersonation is active:
		// Bypass impersonation by using base auth token for request
		const bypassImpersonation = rootGetters['studentImpersonation/isActive'];
		return getSchoolClassesAll(rootState.user.schoolId, archived, bypassImpersonation);
	},
	getSchoolStudents({ rootState }, payload) {
		return getSchoolStudents(rootState.user.schoolId, payload);
	},
	getClassByCode(_, { code }) {
		return getClassByCode(code);
	},
	joinClassByCode({ commit }, { schoolID, classID, code, name, email, password, passwordConfirm }) {
		return joinClassByCode(schoolID, classID, code, name, email, password, passwordConfirm).then(resp => {
			const token = resp.access_token;
			commit('auth/setToken', { token }, { root: true });
			commit('user/setUser', resp.user, { root: true });
		});
	},
	joinSchool(_, { schoolId }) {
		return joinSchool(schoolId);
	},
	getSchoolClassCreateFlow(_, { schoolId }) {
		return getSchoolClassCreateFlow(schoolId);
	},
	getSchoolClassCourses({ rootState }, { schoolClassId }) {
		return getSchoolClassCourses(rootState.user.schoolId, schoolClassId);
	},
	getSchoolClassCourseMaterials({ rootState }, { schoolClassId, courseId }) {
		return getSchoolClassCourseMaterials(rootState.user.schoolId, schoolClassId, courseId);
	},
	createSchoolClass(_, { schoolId, data }) {
		return createSchoolClass(schoolId, data);
	},
	updateSchoolClass(_, { schoolId, schoolClassId, data }) {
		return updateSchoolClass(schoolId, schoolClassId, data);
	},
	deleteSchoolClass(_, { schoolId, schoolClassId }) {
		return deleteSchoolClass(schoolId, schoolClassId);
	},
	restoreSchoolClass(_, { schoolId, schoolClassId }) {
		return restoreSchoolClass(schoolId, schoolClassId);
	},
	getSchoolClassActions(_, { schoolId, schoolClassId }) {
		return getSchoolClassActions(schoolId, schoolClassId);
	},
	removeStudentFromSchoolClass(_, { schoolId, schoolClassId, studentId }) {
		return removeStudentFromSchoolClass(schoolId, schoolClassId, studentId);
	},
	addTeacherToSchoolClass(_, { schoolId, schoolClassId, teacherId }) {
		return addTeacherToSchoolClass(schoolId, schoolClassId, teacherId);
	},
	removeTeacherFromSchoolClass(_, { schoolId, schoolClassId, teacherId }) {
		return removeTeacherFromSchoolClass(schoolId, schoolClassId, teacherId);
	},
	getSchoolClassUniloginSync(_, { schoolId, schoolClassId, data }) {
		return getSchoolClassUniloginSync(schoolId, schoolClassId, data);
	},
	resetStudentLogin(_, { schoolId, studentId, data }) {
		return resetStudentLogin(schoolId, studentId, data);
	},
	renameStudent({ commit }, { schoolId, studentId, name }) {
		const res = renameStudent(schoolId, studentId, name);
		commit('setStudentName', { studentId, name: res.name });
		return res;
	},
	addBooksToSchoolClass(_, { schoolId, schoolClassId, bookIds }) {
		return addBooksToSchoolClass(schoolId, schoolClassId, bookIds);
	},
	removeBookFromSchoolClass(_, { schoolId, schoolClassId, bookId }) {
		return removeBookFromSchoolClass(schoolId, schoolClassId, bookId);
	},
};

const mutations = {
	setSchoolClasses(state, schoolClasses) {
		state.schoolClasses = schoolClasses;
	},
	setStudentName(state, { studentId, name }) {
		state.schoolClasses.forEach(schoolClass => {
			schoolClass.students.forEach(student => {
				if (student.id === studentId) {
					student.name = name;
				}
			});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
