<template>
	<div v-show="achievements" class="tile is-ancestor">
		<div class="tile is-parent">
			<div class="tile is-child box is-rounded p-4">
				<div class="is-flex is-justify-content-space-between">
					<div class="is-flex is-justify-content-start is-align-items-center">
						<BaseIcon
							icon="hexagon-star"
							color="yellow-dark"
							no-padding
							is-fill
							class="mr-2"
						></BaseIcon>
						<div class="title is-5 has-text-black">Seneste bedrifter</div>
					</div>
					<div class="is-flex is-align-items-start">
						<router-link :to="{ name: 'profile' }" class="change-avatar-button">
							<div class="button is-medium is-primary is-fullwidth">
								Se alle
							</div>
						</router-link>
					</div>
				</div>
				<div class="box is-rounded no-box-shadow has-background-grey-lightest py-6 mt-5">
					<div class="columns">
						<div
							v-for="(achievement, index) in sortedAchievements"
							:key="index"
							class="column is-6-mobile is-4-tablet is-3-desktop"
						>
							<AchievementCard :achievement="achievement"></AchievementCard>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';
import AchievementCard from '@/components/dashboard/AchievementCard';
import { mapGetters } from 'vuex';

export default {
	name: 'LatestAchievements',
	components: { AchievementCard, BaseIcon },
	props: {},
	computed: {
		...mapGetters('user', ['achievements']),
		sortedAchievements() {
			return this.achievements
				? [...this.achievements]
						.sort((a, b) => {
							if (a.latest_achieved_at === b.latest_achieved_at) {
								return 0;
							} else if (a.latest_achieved_at === null) {
								return 1;
							} else if (b.latest_achieved_at === null) {
								return -1;
							} else if (a.latest_achieved_at < b.latest_achieved_at) {
								return 1;
							} else {
								return -1;
							}
						})
						.splice(0, 4)
				: [];
		},
	},
	created() {
		this.loadAchievements();
	},
	methods: {
		loadAchievements() {
			return this.$store.dispatch('user/getAchievements');
		},
	},
};
</script>
