import {
	getSchoolClassExerciseAnswerRevisions,
	getSchoolClassHomeworkSectionAnswerRevisions,
	getSchoolClassHomeworkUserAnswerRevisions,
	getSchoolClassResultsOfBookChapters,
	getSchoolClassResultsOfAssignments,
	getSchoolClassResultsOfExercises,
	getStudentOverview,
	getStudentLatestExercises,
	getStudentLatestScreenings,
	getStudentExerciseAnswerRevisions,
	getUserLatestUnfinishedExercises,
} from '@/api/results';

const state = {};

const getters = {};

const actions = {
	getSchoolClassExerciseAnswerRevisions(_, { schoolId, schoolClassId, sectionRelationId, homeworkId }) {
		return getSchoolClassExerciseAnswerRevisions(schoolId, schoolClassId, sectionRelationId, homeworkId);
	},
	getSchoolClassHomeworkSectionAnswerRevisions(_, { schoolId, schoolClassId, homeworkId, sectionRelationId }) {
		return getSchoolClassHomeworkSectionAnswerRevisions(
			schoolId,
			schoolClassId,
			homeworkId,
			sectionRelationId,
		);
	},
	getSchoolClassHomeworkUserAnswerRevisions(_, { schoolId, schoolClassId, homeworkId, userId }) {
		return getSchoolClassHomeworkUserAnswerRevisions(schoolId, schoolClassId, homeworkId, userId);
	},
	getSchoolClassResultsOfBookChapters(_, { schoolId, schoolClassId, bookId }) {
		return getSchoolClassResultsOfBookChapters(schoolId, schoolClassId, bookId);
	},
	getSchoolClassResultsOfAssignments(_, { schoolId, schoolClassId, assignmentIds }) {
		return getSchoolClassResultsOfAssignments(schoolId, schoolClassId, assignmentIds);
	},
	getSchoolClassResultsOfExercises(_, { schoolId, schoolClassId, sectionRelationIds }) {
		return getSchoolClassResultsOfExercises(schoolId, schoolClassId, sectionRelationIds);
	},
	getStudentOverview({ rootState }, { userId, courseId, schoolClassId }) {
		return getStudentOverview(rootState.user.schoolId, userId, courseId, schoolClassId);
	},
	getStudentLatestExercises({ rootState }, { userId, courseId, schoolClassId, onlyScreenings, page }) {
		return getStudentLatestExercises(
			rootState.user.schoolId,
			userId,
			courseId,
			schoolClassId,
			onlyScreenings,
			page,
		);
	},
	getStudentLatestScreenings({ rootState }, { userId }) {
		return getStudentLatestScreenings(rootState.user.schoolId, userId);
	},
	getStudentExerciseAnswerRevisions({ rootState }, { userId, sectionRelationId, homeworkId }) {
		return getStudentExerciseAnswerRevisions(
			rootState.user.schoolId,
			userId,
			sectionRelationId,
			homeworkId,
		);
	},
	getUserLatestUnfinishedExercises({ rootState }) {
		return getUserLatestUnfinishedExercises(rootState.user.schoolId);
	},
};

const mutations = {};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
