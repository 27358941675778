<template>
	<div v-if="activeUser" class="box is-rounded user-profile p-0">
		<div class="">
			<div class="">
				<div
					class="avatar-container px-5 pt-5"
					:style="{ 'background-color': activeUser.color?.hex }"
				>
					<Avatar :avatar="activeUser.avatar" size="xlarge" />
					<div
						v-if="editable"
						class="change-avatar-button"
						@click="showChangeAvatarModal"
					>
						<baseIcon
							icon="change-avatar"
							:size="3"
							color="blue"
							hover-color="blue-dark"
							is-fill
						/>
					</div>
					<router-link
						v-else-if="!hideProfileLink"
						:to="{ name: 'profile' }"
						class="change-avatar-button"
					>
						<baseIcon
							icon="pencil"
							:size="3"
							color="blue"
							hover-color="blue-dark"
							is-fill
						/>
					</router-link>
				</div>
				<div v-if="withStudentResetLink" class="px-5 pt-3">
					<button class="button is-medium mx-auto is-block" @click="showStudentReset">
						Nulstil login
					</button>
				</div>
				<div class="px-5 pt-3">
					<div v-if="editable" class="">
						<BaseField>
							<BaseInput
								v-model="newName"
								:has-error="!isNameValid"
								:disabled="!canEditName"
								placeholder="Navn"
							/>
						</BaseField>
						<BaseField>
							<BaseInput
								v-model="newEmail"
								:has-error="!isEmailValid"
								:class="{ 'field-to-focus': focusEmailField }"
								placeholder="Email"
							/>
						</BaseField>
						<div
							v-if="isNameOrEmailChanged || needsConfirmation"
							class="buttons is-right"
						>
							<button
								:disabled="!isEmailValid || !isNameValid"
								type="button"
								class="button is-medium is-primary"
								@click="saveNameAndEmail"
							>
								{{ submitText }}
							</button>
						</div>
						<div v-if="school" class="title is-8 py-3 has-text-grey-light">
							{{ school.name }}
						</div>
					</div>
					<template v-else>
						<div class="title is-5 has-text-black py-1 m-0">
							<span>{{ activeUser.name }}</span>
							<div
								v-if="withTeacherRename"
								v-tippy="{ content: 'Ret navn' }"
								class="ml-2 is-clickable is-inline-block has-vertical-align-bottom"
								@click="showRenameStudentModal"
							>
								<baseIcon
									icon="pencil"
									:size="2"
									color="grey"
									hover-color="blue"
									is-fill
								/>
							</div>
						</div>
						<div v-if="school" class="title is-7 pb-3 has-text-grey-light">
							{{ school.name }}
						</div>
					</template>
				</div>
			</div>
		</div>

		<div v-if="statistics" class="statistics-container">
			<div
				v-for="item in statistics"
				:key="item.title"
				class="is-flex py-3 px-5"
				:class="{ 'has-background-grey-lightest': item.type === 'points' }"
			>
				<div class="pr-4">
					<ExerciseIcon
						:type="item.icon"
						:show-background="item.type !== 'points'"
						size="large"
					/>
				</div>
				<div class="">
					<div class="title has-text-grey-light is-9">{{ item.title }}</div>
					<div class="subtitle has-text-black is-6">
						<b>{{ formatLargeNumber(item.value ?? 0) }}</b>
					</div>
				</div>
			</div>
		</div>

		<SplitModal
			ref="changeAvatarModal"
			:image-width="4"
			:left-column-classes="['has-background-grey-lighterer']"
			:footer-column-classes="['has-background-white']"
		>
			<template #img>
				<div class="my-6 mx-auto pb-6" style="width: 250px">
					<div class="is-relative">
						<Avatar
							:avatar="activeAvatar"
							:color="activeColor"
							size="xlarge"
							class="mx-auto"
						/>
						<div class="color-list mx-5 mt-5">
							<div
								v-for="color in colors"
								:key="color.id"
								:style="{ backgroundColor: color.hex }"
								:class="{ active: color.id === activeColor?.id }"
								class="color-item"
								@click="activeColor = color"
							></div>
						</div>
					</div>
				</div>
			</template>

			<template #body>
				<p class="title is-3 mb-0">Profilbillede</p>
				<div class="mb-5 avatar-list">
					<div
						v-for="avatar in avatars"
						:key="avatar.id"
						:class="{ 'is-active': avatar.id === activeAvatar?.id }"
						class="avatar-item"
						@click="activeAvatar = avatar"
					>
						<Avatar
							:avatar="avatar"
							:color="defaultColor"
							:highlight="avatar.id === activeAvatar?.id"
						/>
					</div>
				</div>
			</template>

			<template #footer>
				<div class="buttons is-right">
					<button type="button" class="button is-medium" @click="hideAvatarModal">
						Luk
					</button>
					<button type="button" class="button is-medium is-primary" @click="saveAvatar">
						Gem
					</button>
				</div>
			</template>
		</SplitModal>

		<ResetStudentLoginModal
			ref="resetStudentLoginModal"
			:student="activeUser"
			@updated="$emit('reload-student')"
		/>

		<RenameStudentModal ref="renameStudentModal" :student="activeUser" @updated="$emit('reload_student')" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from '@/components/ui/user/Avatar.vue';
import ExerciseIcon from '@/components/exercises/ExerciseIcon';
import { formatLargeNumber, round } from '@/utils/math';
import BaseInput from '@/components/base/input/BaseInput';
import BaseField from '@/components/base/input/BaseField';
import BaseIcon from '@/components/base/BaseIcon';
import RenameStudentModal from '@/components/student/RenameStudentModal';
import ResetStudentLoginModal from '@/components/student/ResetStudentLoginModal';
import SplitModal from '@/components/ui/modal/SplitModal';

export default {
	name: 'ProfileWithStats',
	components: {
		Avatar,
		BaseField,
		BaseInput,
		BaseIcon,
		ExerciseIcon,
		RenameStudentModal,
		ResetStudentLoginModal,
		SplitModal,
	},
	props: {
		editable: {
			type: Boolean,
			default: false,
		},
		renameable: {
			type: Boolean,
			default: false,
		},
		userId: {
			type: Number,
			default: null,
		},
		overrideUser: {
			type: Object,
			default: null,
		},
		hideProfileLink: {
			type: Boolean,
			default: false,
		},
		withStudentResetLink: {
			type: Boolean,
			default: false,
		},
		withTeacherRename: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			avatars: [],
			colors: [],
			activeAvatar: null,
			activeColor: null,
			newName: '',
			newEmail: '',
			defaultColor: {
				hex: '#f2f5f8',
			},
			userAchievements: null,
		};
	},
	computed: {
		...mapGetters('user', ['user', 'isTeacher', 'school', 'achievements']),
		...mapGetters('school', ['schoolClasses']),
		activeUser() {
			if (this.overrideUser) {
				return this.overrideUser;
			} else if (this.userId) {
				return this.getStudentFromId(this.userId);
			} else {
				return this.user;
			}
		},
		statistics() {
			if (this.userId) {
				return this.userAchievements
					? this.getStatisticsFromAchievements(this.userAchievements)
					: null;
			} else {
				return this.achievements ? this.getStatisticsFromAchievements(this.achievements) : null;
			}
		},
		isNameOrEmailChanged() {
			return this.user.name !== this.newName || this.user.email !== this.newEmail;
		},
		canEditName() {
			return this.isTeacher || this.user.unilogin_id === null;
		},
		isNameValid() {
			return this.newName.trim() !== '';
		},
		isEmailValid() {
			if (this.newEmail === null || this.newEmail.trim() === '') {
				return !!this.user.unilogin_id;
			} else {
				const regex = /^[a-z0-9+!#$%&‘*\-/=?^_`.{|}~]{2,}@[^@]{2,}\.[^@]{2,}$/i;
				return regex.exec(this.newEmail.trim()) !== null;
			}
		},
		focusEmailField() {
			return this.$route.query?.focus === 'email';
		},
		needsConfirmation() {
			return !this.user.information_confirmed_at;
		},
		submitText() {
			if (this.needsConfirmation) {
				return 'Bekræft oplysninger';
			}

			return 'Gem';
		},
	},
	created() {
		this.loadAchievements();
		this.loadAvatars();
		this.loadColors();
		this.activeAvatar = this.user.avatar;
		this.activeColor = this.user.color;
		this.newName = this.user.name;
		this.newEmail = this.user.email;
	},
	methods: {
		loadAchievements() {
			if (this.userId) {
				this.$store
					.dispatch('user/getUserAchievementLevels', { user_id: this.userId })
					.then(({ data }) => {
						this.userAchievements = data;
					});
			} else {
				return this.$store.dispatch('user/getAchievements');
			}
		},
		loadAvatars() {
			return this.$store.dispatch('user/getAvatars').then(avatars => {
				this.avatars = avatars;
			});
		},
		loadColors() {
			return this.$store.dispatch('user/getColors').then(colors => {
				this.colors = colors;
			});
		},
		showChangeAvatarModal() {
			this.$refs.changeAvatarModal.show();
		},
		saveAvatar() {
			this.$store
				.dispatch('user/updateProfile', {
					avatar_id: this.activeAvatar.id,
					color_id: this.activeColor.id,
				})
				.then(() => {
					this.$refs.changeAvatarModal.hide();
				});
		},
		hideAvatarModal() {
			this.$refs.changeAvatarModal.hide();
		},
		saveNameAndEmail() {
			if (!this.isNameValid || !this.isEmailValid) {
				return;
			}
			const payload = {
				email: this.newEmail?.trim(),
			};
			if (this.canEditName) {
				payload.name = this.newName?.trim();
			}
			this.$store.dispatch('user/updateProfile', payload).then(() => {
				this.newName = this.user.name;
				this.newEmail = this.user.email;
			});
		},
		getStatisticsFromAchievements(achievements) {
			const achievementLevels = achievements.map(a => a.levels).flat();
			const res = [
				{
					title: 'Niveau 1',
					icon: 'difficulty1',
					value: 0,
					type: 'completed_exercises',
					difficulty: 1,
				},
				{
					title: 'Niveau 2',
					icon: 'difficulty2',
					value: 0,
					type: 'completed_exercises',
					difficulty: 2,
				},
				{
					title: 'Niveau 3',
					icon: 'difficulty3',
					value: 0,
					type: 'completed_exercises',
					difficulty: 3,
				},
				{
					title: 'Eksamensopgaver',
					icon: 'exam',
					value: 0,
					type: 'completed_exercises',
					difficulty: 4,
				},
				{
					title: 'Point',
					icon: 'points',
					value: 0,
					type: 'points',
					difficulty: null,
				},
			];
			for (let i = 0; i < Object.keys(res).length; i++) {
				const k = Object.keys(res)[i];
				const e = res[k];
				const achievementLevel = achievementLevels.find(
					ual => ual.type === e.type && ual.difficulty === e.difficulty,
				);
				if (!achievementLevel) {
					continue;
				}
				if (achievementLevel.user_achievement_level) {
					res[k].value = achievementLevel.user_achievement_level.amount;
				}
			}
			return res;
		},
		getStudentFromId(id) {
			return this.schoolClasses
				.map(c => c.students)
				.flat()
				.find(s => s.id === id);
		},
		showStudentReset() {
			this.$refs.resetStudentLoginModal.show();
		},
		showRenameStudentModal() {
			this.$refs.renameStudentModal.show();
		},
		formatLargeNumber,
		round,
	},
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/abstracts/variables';
.user-profile {
	padding-bottom: 0;

	.avatar-container {
		height: 8rem;
		position: relative;
		border-top-left-radius: $radius-larger;
		border-top-right-radius: $radius-larger;

		.avatar {
			position: absolute;
			bottom: 0;
			left: 2.5rem;
		}
		.change-avatar-button {
			position: absolute;
			top: 1rem;
			right: 1rem;
			width: 3rem;
			height: 3rem;
			border-radius: 50%;
			background-color: $white;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: background-color 200ms linear;
			&:hover {
				//background-color: $blue-lightest;
			}
		}
	}

	.statistics-container {
		border-bottom-left-radius: $radius-larger;
		border-bottom-right-radius: $radius-larger;
		overflow: hidden;
	}

	.avatar-list {
		display: grid;
		column-gap: 10px;
		row-gap: 15px;
		grid-template-columns: repeat(8, 1fr);
		.avatar-item {
			position: relative;
			cursor: pointer;
		}
	}
	.color-list {
		display: flex;
		justify-content: space-between;
		.color-item {
			position: relative;
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
			cursor: pointer;
			&:after {
				content: '';
				position: absolute;
				top: -20%;
				left: -20%;
				width: 140%;
				height: 140%;
				border-radius: 50%;
				border: 2px solid transparent;
				transition: border-color 200ms linear;
			}
			&.active:after {
				border: 2px solid $blue;
			}
		}
	}
}
</style>

<style lang="scss">
@import '@/assets/sass/abstracts/variables';
.field-to-focus {
	animation: blink 0.4s 6;
	animation-delay: 1s;
	background: transparent;
}

@keyframes blink {
	0%,
	49.99% {
		background: transparent;
	}
	50%,
	99.9% {
		background: $blue-lighter;
	}
}
</style>
