<template>
	<div v-if="schoolClassCourseMaterials">
		<MaterialCardList :items="itemsToShow">
			<template #item="slotProps">
				<router-link :to="slotProps.item.route">
					<BookCard
						v-if="slotProps.item.type === 'book'"
						:book="slotProps.item.book"
						:size="cardSize"
						animate
					/>
					<CourseExercisesCard
						v-else-if="slotProps.item.type === 'exercises'"
						:course="course"
						:size="cardSize"
						animate
					/>
					<CourseTrainingHomeworkCard
						v-else-if="slotProps.item.type === 'training'"
						:course="course"
						:size="cardSize"
						animate
					/>
					<CourseAssignmentsCard
						v-else-if="slotProps.item.type === 'assignments'"
						:image="slotProps.item.image"
						:count="slotProps.item.count"
						:title="slotProps.item.title"
						:size="cardSize"
						animate
					/>
					<MaterialCard
						v-else-if="slotProps.item.type === 'screenings'"
						:image="slotProps.item.info.image"
						:count="slotProps.item.info.count"
						:title="slotProps.item.info.title"
						:description="slotProps.item.info.description"
						:size="cardSize"
						animate
					/>
				</router-link>
			</template>
		</MaterialCardList>
		<div v-if="!isShowingAllMaterials && allCourseItems.length > 3" class="has-text-centered mt-3">
			<button type="button" class="button is-medium is-primary" @click="isShowingAllMaterials = true">
				Flere aktiviteter
			</button>
		</div>
	</div>
</template>

<script>
import MaterialCardList from '@/components/materials/MaterialCardList';
import BookCard from '@/components/books/BookCard';
import CourseAssignmentsCard from '@/components/course/CourseAssignmentsCard';
import CourseExercisesCard from '@/components/course/CourseExercisesCard';
import CourseTrainingHomeworkCard from '@/components/course/CourseTrainingHomeworkCard';
import MaterialCard from '@/components/course/MaterialCard.vue';
import { mapState } from 'vuex';

export default {
	name: 'CourseMaterialList',
	components: {
		MaterialCard,
		MaterialCardList,
		BookCard,
		CourseAssignmentsCard,
		CourseExercisesCard,
		CourseTrainingHomeworkCard,
	},
	props: {
		course: {
			type: Object,
			required: true,
		},
		schoolClass: {
			type: Object,
			required: true,
		},
		recommendedBooksCount: {
			type: Number,
			default: 2,
		},
		showAssignmentItem: {
			type: Boolean,
			default: true,
		},
		showScreeningAssignmentItem: {
			type: Boolean,
			default: true,
		},
		cardSize: {
			type: String,
			default: 'normal',
		},
	},
	data() {
		return {
			schoolClassCourseMaterials: null,
			isShowingAllMaterials: false,
		};
	},
	computed: {
		...mapState('app', ['config']),
		courseRecommendedItems() {
			return [
				...this.recommendedBooks
					.map(book => ({
						type: 'book',
						route: this.getBookRoute(book),
						book,
					}))
					.slice(0, 1),
				...this.courseTrainingItems.slice(-1),
				// ...this.courseAssignmentItems.slice(0, 1),
				...this.courseScreeningItems,
			];
		},
		allCourseItems() {
			// return [];
			return [
				...this.recommendedBooks.map(book => ({
					type: 'book',
					route: this.getBookRoute(book),
					book,
				})),
				...this.courseTrainingItems,
				...this.courseAssignmentItems,
				...this.courseScreeningItems,
			];
		},
		itemsToShow() {
			return this.isShowingAllMaterials ? this.allCourseItems : this.courseRecommendedItems;
		},
		recommendedBooks() {
			if (!this.schoolClassCourseMaterials?.recommended_books) {
				return [];
			}
			return this.schoolClassCourseMaterials.recommended_books
				.map(bookId => this.schoolClassCourseMaterials.books.find(book => book.id === bookId))
				.filter(book => book)
				.slice(0, this.recommendedBooksCount);
		},
		courseAssignmentItems() {
			const items = [];
			const exam = this.schoolClassCourseMaterials.assignment_data.exam;
			if (exam?.count) {
				items.push({
					type: 'assignments',
					count: exam.count,
					image: exam.image,
					title: 'Eksamensopgaver',
					route: {
						name: 'homework_create_assignment',
						params: {
							class_id: this.schoolClass.id,
							course_id: this.course.id,
						},
						query: {
							uddannelse: exam.education_id,
							type: exam.type_id,
						},
					},
				});
			}

			if (this.schoolClassCourseMaterials.assignment_data.total?.count) {
				items.push({
					type: 'assignments',
					count: this.schoolClassCourseMaterials.assignment_data.total.count,
					image: this.schoolClassCourseMaterials.assignment_data.total.image,
					title: 'Opgavesæt',
					route: {
						name: 'homework_create_assignment',
						params: {
							class_id: this.schoolClass.id,
							course_id: this.course.id,
						},
					},
				});
			}

			return items;
		},
		courseTrainingItems() {
			const items = [];
			items.push({
				type: 'exercises',
				route: {
					name: 'homework_create_exercises',
					params: {
						class_id: this.schoolClass.id,
						course_id: this.course.id,
					},
				},
			});
			if (this.course.has_training) {
				items.push({
					type: 'training',
					route: {
						name: 'homework_create_training',
						params: {
							class_id: this.schoolClass.id,
							course_id: this.course.id,
						},
					},
				});
			}
			return items;
		},
		courseScreeningItems() {
			const items = [];
			if (this.showScreeningAssignmentItem) {
				const title = this.schoolClass.level.school_type_id === 2 ? 'Screeninger' : 'Prøver';
				const description =
					this.schoolClass.level.school_type_id === 2
						? 'Opret en start- eller midtvejsscreening'
						: 'Opret en diktat eller prøve.';
				items.push({
					type: 'screenings',
					route: {
						name: 'screenings',
						query: { vis: this.course.id },
					},
					info: {
						title: title,
						description: description,
						image: '/images/homework/startscreening.svg',
						count: this.course.screening_assignments.length,
					},
				});
			}
			return items;
		},
		addBookTooltip() {
			return 'Du har adgang til denne bog, men du har endnu ikke givet den til dit hold. Klik for at tilføje den.';
		},
	},
	watch: {
		course: {
			handler() {
				this.loadSchoolCourseMaterials();
			},
		},
		schoolClass: {
			handler() {
				this.loadSchoolCourseMaterials();
			},
		},
	},
	created() {
		this.loadSchoolCourseMaterials();
	},
	methods: {
		getBookRoute(book) {
			return {
				name: 'homework_create_book',
				params: {
					class_id: this.schoolClass.id,
					book_id: book.id,
				},
			};
		},
		loadSchoolCourseMaterials() {
			if (this.course && this.schoolClass) {
				return this.$store
					.dispatch('school/getSchoolClassCourseMaterials', {
						schoolClassId: this.schoolClass.id,
						courseId: this.course.id,
					})
					.then(materials => {
						this.schoolClassCourseMaterials = materials;
					});
			} else {
				this.isShowingAllMaterials = false;
			}
		},
	},
};
</script>
