<template>
	<div class="modal" :class="{ 'is-active': active }">
		<div class="modal-background" :class="animateCss(['fadeIn'])" @click="hide"></div>

		<div
			class="modal-content"
			:class="animateCss(['fadeInUp', 'faster', 'delay-1s'])"
			:style="{ width: `${width}px` }"
		>
			<slot v-if="active"></slot>
		</div>
		<button class="modal-close is-large" aria-label="close" @click="hide"></button>
	</div>
</template>

<script>
import animateCss from '@/mixins/animateCss';

export default {
	name: 'LightModal',
	mixins: [animateCss],
	props: {
		width: {
			type: Number,
			default: 600,
		},
	},
	data() {
		return {
			active: false,
		};
	},
	methods: {
		show() {
			this.$emit('show');
			this.active = true;
			this.$emit('shown');
		},
		hide() {
			this.$emit('hide');
			this.active = false;
			this.$emit('hidden');
		},
	},
};
</script>
