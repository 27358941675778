<template>
	<SplitModal
		ref="modal"
		title="Omdøb elev"
		:class="{ 'has-text-left': true }"
		:tooltip="modalTooltip"
		@hide="onHide"
	>
		<template #img>
			<img src="/images/modals/pushing-buttons.png" alt="image" />
		</template>

		<template #body>
			<BaseLoader v-if="loading" size="medium" class="mt-6" />

			<template v-else>
				<BaseField>
					<BaseInput
						ref="input"
						v-model="newName"
						placeholder="Navn"
						@keydown.enter="saveName"
					/>
				</BaseField>

				<template v-if="validationErrors.name">
					<p v-for="(err, i) in validationErrors.name" :key="i" class="help is-danger">
						{{ err }}
					</p>
				</template>
				<div class="is-flex is-justify-content-space-between">
					<div
						v-tippy="{
							placement: 'bottom',
							content: resetButtonTooltip,
							onShow: () => !!resetButtonTooltip,
						}"
					>
						<button
							:disabled="!canReset"
							type="button"
							class="button is-medium is-primary is-light"
							@click="resetName"
						>
							Nulstil
						</button>
					</div>
					<button type="button" class="button is-medium is-primary" @click="saveName">
						Gem
					</button>
				</div>
			</template>
		</template>
	</SplitModal>
</template>

<script>
import BaseLoader from '@/components/base/BaseLoader';
import SplitModal from '@/components/ui/modal/SplitModal';
import { required, email } from 'vuelidate/lib/validators';
import { ValidationError } from '@/api/errors';
import { mapState } from 'vuex';
import BaseInput from '@/components/base/input/BaseInput';
import BaseField from '@/components/base/input/BaseField';

export default {
	name: 'RenameStudentModal',
	components: { SplitModal, BaseField, BaseInput, BaseLoader },
	props: {
		student: {
			type: Object,
			default: null,
		},
	},
	validations: {
		email: {
			required,
			email,
		},
	},
	data: () => ({
		loading: false,
		newName: '',
		validationErrors: {},
		resetDone: false,
	}),
	computed: {
		...mapState('user', ['schoolId']),
		...mapState('school', ['schoolClasses']),
		modalTooltip() {
			return 'Vi får automatisk navne fra UNI-Login. Her kan du dog give eleven et nyt navn. Eleverne kan ikke selv ændre deres navne. Vælg “Nulstil”, hvis du vil nulstille elevens navn til navnet fra UNI-Login.';
		},
		canReset() {
			return this.student && !!this.student.unilogin_id;
		},
		resetButtonTooltip() {
			if (this.canReset) {
				return null;
			} else {
				return 'Eleven har ikke et UNI-Login tilknyttet';
			}
		},
	},
	watch: {
		student: {
			immediate: true,
			handler() {
				this.reset();
			},
		},
	},
	created() {
		this.reset();
	},
	methods: {
		reset() {
			if (this.student) {
				this.newName = this.student.name;
			}
			this.validationErrors = {};
		},
		onHide() {
			this.reset();
		},
		hide() {
			this.onHide();
			this.$refs.modal.hide();
		},
		show() {
			this.$refs.modal.show();
			// wait 1 second before focusing input, to avoid issues with modal animations
			setTimeout(() => {
				this.$refs.input.focus();
			}, 1000);
		},
		saveName() {
			return this.sendNameRequest(this.newName);
		},
		resetName() {
			if (this.canReset) {
				return this.sendNameRequest('');
			}
		},
		sendNameRequest(newName) {
			this.loading = true;
			this.validationErrors = {};
			this.$store
				.dispatch('school/renameStudent', {
					schoolId: this.schoolId,
					studentId: this.student.id,
					name: newName,
				})
				.catch(err => {
					if (err instanceof ValidationError) {
						this.validationErrors = err.validationErrors;
					}
				})
				.then(() => {
					this.$emit('updated', this.student);
					this.hide();
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
