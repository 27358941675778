<template>
	<div v-if="unfinishedExercises.length > 0" class="tile is-ancestor">
		<div class="tile is-parent">
			<div class="tile is-child box is-rounded p-4">
				<div class="is-flex is-align-items-center mb-5">
					<BaseIcon icon="flame" color="red" class="mr-2" />
					<div class="title is-5 has-text-black">Fortsæt</div>
				</div>
				<div class="box is-rounded no-box-shadow has-background-grey-lightest py-5 mt-5">
					<StudentExerciseProgress
						v-for="(exercise, i) in unfinishedExercises"
						:key="`continue-${i}`"
						:section="exercise.section"
						:homework="exercise.homework"
						:progress="exercise.progress"
						:course-id="exercise.course_id ?? 0"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';
import Section from '@/models/section/Section';
import StudentExerciseProgress from '@/components/dashboard/StudentExerciseProgress';

export default {
	name: 'UnfinishedExercises',
	components: { BaseIcon, StudentExerciseProgress },
	data() {
		return {
			unfinishedExercises: [],
		};
	},
	created() {
		this.loadUserLatestUnfinishedExercises();
	},
	methods: {
		loadUserLatestUnfinishedExercises() {
			return this.$store.dispatch('results/getUserLatestUnfinishedExercises').then(entries => {
				this.unfinishedExercises = entries.map(entry => {
					entry.section = new Section(entry.section);
					return entry;
				});
			});
		},
	},
};
</script>
