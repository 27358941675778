<template>
	<div class="embed">
		<iframe
			class="embed-item"
			:src="`https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&api=1`"
			frameborder="0"
			webkitallowfullscreen
			mozallowfullscreen
			allowfullscreen
		/>
	</div>
</template>

<script>
export default {
	name: 'VimeoVideo',
	props: {
		videoId: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.embed {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;

	.embed-item {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
</style>
